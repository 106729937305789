import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'

import logo from './logo.png';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Linkedin } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';

import mg from './mg.png';
import inmobi from './inmobi.png';
import mandarina from './mandarina.png';
import afirmativo from './afirmativo.png';

//import { useState, useEffect, useRef } from 'react';
/*const handleNavClick = (eventKey) => {
  const yOffset = -100; 
  const element = eval(eventKey).current;
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({top: y, behavior: 'smooth'});
}
const refHome = useRef(null);*/
function App() {
  const refHome = useRef(null);
  const refUs = useRef(null);
  const refDiv = useRef(null);

  const [ lgShow, setLgShow ] = useState(false);

  const handleNavClick = (eventKey) => {
    const yOffset = -100; 
    const element = eval(eventKey).current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  return (
    <>
      <div>

        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              AVISO DE PRIVACIDAD
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>GRUPO MEBS, S.A. DE C.V., (en lo sucesivo MEBS), con domicilio en Jos&eacute; Guadalupe Posada n&uacute;mero 16, colonia Ciudad Sat&eacute;lite, Naucalpan de Ju&aacute;rez, C.P. 53100, Estado de M&eacute;xico es el responsable del uso y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:&nbsp;</p>

            <p>&nbsp;</p>

            <p>Los datos personales que en su caso nos proporcione, los utilizaremos para las siguientes finalidades:&nbsp;</p>

            <p>&nbsp;</p>

            <ol>
              <li>Proveerle los servicios o productos que comercializamos.</li>
              <li>Brindarle los servicios que nos solicite.</li>
              <li>Conocer sus necesidades de productos o servicios para estar en posibilidad de ofrecerle los que m&aacute;s se adecuen a sus preferencias.</li>
              <li>Enviar por cualquier medio f&iacute;sico o electr&oacute;nico (correos electr&oacute;nicos, mensajes de texto, redes sociales, mensajer&iacute;a instant&aacute;nea, etc.), conocido o por conocerse, informaci&oacute;n de car&aacute;cter comercial, de mercadeo y promocional sobre los servicios o promociones desarrolladas por MEBS, sus empresas filiales, subsidirias y/o relacionadas, as&iacute; como de sus socios comerciales y/o proveedores.</li>
              <li>Identificaci&oacute;n y contacto.</li>
              <li>Fines estad&iacute;sticos.</li>
              <li>Elaboraci&oacute;n de bases de datos.</li>
              <li>Atender quejas y aclaraciones, y en su caso, tratarlos para fines compatibles con los mencionados en este Aviso de privacidad y que se consideren an&aacute;logos para efectos legales.</li>
            </ol>

            <p>&nbsp;</p>

            <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, podremos solicitarle los siguientes datos personales:</p>

            <ol>
              <li>Nombre.</li>
              <li>Tel&eacute;fono.</li>
              <li>Correo electr&oacute;nico.</li>
            </ol>

            <p>MEBS no recaba Datos Personales Sensibles. No obstante, si se diera el caso, se obtendr&aacute; el consentimiento expreso y por escrito del titular para su tratamiento, a trav&eacute;s de su firma aut&oacute;grafa, firma electr&oacute;nica o cualquier mecanismo de autenticaci&oacute;n.</p>

            <p>&nbsp;</p>

            <p>Le informamos que sus datos personales pueden ser compartidos dentro y fuera del pa&iacute;s para las finalidades antes mencionadas, &uacute;nicamente con empresas filiales, subsidirias y/o relacionadas con MEBS.</p>

            <p>&nbsp;</p>

            <p>Usted acepta y reconoce que las transferencias anteriormente descritas son necesarias para cumplir con las obligaciones que tiene pactadas MEBS con usted por lo que no se requiere su consentimiento para realizar las mismas.</p>

            <p>&nbsp;</p>

            <p>Asimismo, le informamos que para el desarrollo de algunos servicios, productos y/o promociones MEBS podr&aacute; requerir la participaci&oacute;n de socios comerciales y/o proveedores a los cuales se les podr&aacute; dar acceso a nuestras bases de datos para finalidades especificas, en el entendido que en todo momento MEBS conservar&aacute; la propiedad de dichas bases de datos.</p>

            <p>&nbsp;</p>

            <p>Usted tiene derecho a conocer que datos personales tenemos de usted, para que los utilizamos y las condiciones del uso que les damos (Acceso).&nbsp;</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que estar desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no este siendo utilizada adecuadamente (Cancelaci&oacute;n) as&iacute; como oponerse al uso de sus datos personales para fines especif&iacute;cos (Oposici&oacute;n). Estos derechos se conocen como Derechos ARCO.</p>

            <p>&nbsp;</p>

            <p>Para revocar su consentimiento para el tratamiento de sus datos personales y el ejercicio de sus Derechos ARCO, deber&aacute; presentar una solicitud (la &ldquo;Solicitud ARCO&rdquo;), la cual deber&aacute; enviar al correo electr&oacute;nico contacto@grupomebs.com, acompa&ntilde;ada de la siguiente informaci&oacute;n y documentaci&oacute;n:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Su nombre, domicilio y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud ARCO.</li>
              <li>Los documentos que acrediten su identidad (copia de IFE, pasaporte o cualquier otra identificaci&oacute;n oficial) o en su caso,los documentos que acrediten su representaci&oacute;n legal.</li>
              <li>Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de los Derechos ARCO.</li>
              <li>Cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales.</li>
              <li>En caso de solicitar una rectificaci&oacute;n de datos, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar ladocumentaci&oacute;n que sustente su petici&oacute;n, y la indicaci&oacute;n del lugar donde se podr&aacute;n revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</li>
            </ul>

            <p>&nbsp;</p>

            <p>MEBS responder&aacute; su Solicitud ARCO y expresar&aacute;&nbsp; los motivos de su decisi&oacute;n mediante un correo electr&oacute;nico en un plazo m&aacute;ximo de 20 d&iacute;as naturales contados desde el d&iacute;a en que se haya recibido su Solicitud ARCO.&nbsp;</p>

            <p>&nbsp;</p>

            <p>En caso de que la Solicitud ARCO se conteste de manera afirmativa o procedente, los cambios solicitados se har&aacute;n en un plazo m&aacute;ximo de 15 d&iacute;as naturales.&nbsp;</p>

            <p>&nbsp;</p>

            <p>Los plazos antes referidos se podr&aacute;n prorrogar por una sola vez por un periodo igual en caso de ser necesario.</p>

            <p>&nbsp;</p>

            <p>MEBS podr&aacute; negar el acceso para que usted ejerza los Derechos ARCO en los siguientes supuestos:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Cuando Usted no sea el titular de los datos personales, o no pueda acreditar la representaci&oacute;n del titular.</li>
              <li>Cuando sus datos personales no obren en las bases de datos de MEBS.</li>
              <li>Cuando se lesionen los derechos de un tercero.</li>
              <li>Cuando exista un impedimento legal o la resoluci&oacute;n de una autoridad competente, que restrinja sus Derechos ARCO, y cuando la rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n haya sido previamente realizada.</li>
            </ul>

            <p>&nbsp;</p>

            <p>La negativa podr&aacute; ser parcial, en cuyo caso MEBS efectuar&aacute; el acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n en la parte procedente.</p>

            <p>En el caso de revocar el consentimiento que&nbsp; nos haya otorgado para el tratamiento de sus datos personales es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando servicios.</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Con el objetivo de mejorar la experiencia de sus usuarios en sus sitios web, MEBS podr&aacute; utilizar &ldquo;cookies&rdquo; y/o &ldquo;web beacons&rdquo;. Las &quot;cookies&quot; son archivos de texto que son descargados autom&aacute;ticamente y almacenados en el disco duro del equipo de c&oacute;mputo del usuario al navegar en una p&aacute;gina de Internet para recordar algunos datos sobre este usuario, entre ellos, sus preferencias para la visualizaci&oacute;n de las p&aacute;ginas en ese servidor, nombre y contrase&ntilde;a. Por su parte, las &quot;web beacons&quot; son im&aacute;genes insertadas en un p&aacute;gina de Internet o correo electr&oacute;nico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario, duraci&oacute;n del tiempo de interacci&oacute;n en dicha p&aacute;gina y el tipo de navegador utilizado, entre otros. Le informamos que utilizamos &quot;cookies&quot; y &quot;web beacons&quot; con fines &uacute;nicamente de marketing, experiencia de usuario y medici&oacute;n, para obtener informaci&oacute;n como la siguiente:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Su tipo de navegador y sistema operativo;</li>
              <li>Las p&aacute;ginas de Internet que visita;</li>
              <li>Los v&iacute;nculos que sigue;</li>
              <li>La direcci&oacute;n IP; y el sitio que visit&oacute; antes de entrar al nuestro.</li>
            </ul>

            <p>&nbsp;</p>

            <p>Estas cookies y otras tecnolog&iacute;as pueden ser deshabilitadas. Para conocer c&oacute;mo hacerlo, consulte el men&uacute; de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las &quot;cookies&quot;, es posible que no pueda acceder a ciertas funciones personalizadas en nuestro sitio web.</p>

            <p>&nbsp;</p>

            <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras praticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>

            <p>&nbsp;</p>

            <p>El sitio de internet https://grupomebs.com/ puede contener enlaces a otros sitios web, respecto de los cuales MEBS no se hace responsable de las pr&aacute;cticas y usos de los otros sitios, incluyendo los de nuestros patrocinadores y/o socios comerciales</p>

            <p>&nbsp;</p>

            <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s de nuestro sitio web https://grupomebs.com/.</p>

            <p>&nbsp;</p>

            <p>El Responsable cuenta con medidas de seguridad f&iacute;sicas, administrativas y t&eacute;cnicas para resguardar sus Datos Personales. No obstante, en caso de que ocurra alguna vulneraci&oacute;n a la seguridad que afecte de forma significativa sus derechos patrimoniales o morales, el Responsable lo har&aacute; de su conocimiento mediante el correo electr&oacute;nico que proporcion&oacute;, a fin de que usted pueda tomar las medidas necesarias correspondientes para la defensa de los mismos.</p>

            <p>&nbsp;</p>

            <p>En el momento que usted se registra en nuestro portal o nos proporciona sus datos personales usted manifiesta su consentimiento expreso para el tratamiento de sus datos personales de conformidad con este Aviso de Privacidad.</p>

          </Modal.Body>
        </Modal>
          
      <Navbar fixed="top" expand="lg"  bg="dark" variant="dark">
        <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav onSelect={handleNavClick} className="justify-content-center align-items-center" style={{ flex: 1}} >
          <Nav.Link>
            <img src={ logo } width="60" height="60" className="d-inline-block align-top pading" />
          </Nav.Link>
          <Nav.Link eventKey="refHome" className="nav-link">Inicio</Nav.Link>
          <Nav.Link eventKey="refUs" className="nav-link">¿Quiénes somos?</Nav.Link>
          <Nav.Link eventKey="refDiv" className="nav-link">Divisiones</Nav.Link>
          <Nav.Link className="nav-link" href='https://www.linkedin.com/company/grupo-mebs/'><Linkedin className='icon-nav'/></Nav.Link>
        </Nav>    
        </Navbar.Collapse>
        </Container>
                
      </Navbar>
      </div>
      <div className='clearfixe'></div>
      <div ref={ refHome }>
        <div>
          <ReactPlayer url='gm.mp4' playing={ true } loop={ true } muted={ true } controls={ true } className="player-style"/>
        </div>
      </div>
      {/*<div className="video">
        <h1>video</h1>
      </div>*/}
      
      <Container ref={ refUs }>
        <Row>
          <Col>
            <div className='somos'>
              <div className="section-title">¿Quiénes somos?</div>
              <div className='section-desc-p'>Somos un grupo dedicado a crear, diseñar e innovar en el mundo de la publicidad, marketing, diseño industrial integral y real estate. Acompañamos a nuestros clientes a lo largo del desarrollo de sus proyectos, implementando estrategias creativas y potencializando sus proyectos. Además, aprovechamos las sinergias de un equipo multidisciplinario, profesional y con experiencia en cada sector.</div>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <br/>

      <div ref={ refDiv }>
        <Container>
          {/*<Row className="align-items-center manita pb-4"  onClick={() => window.open('https://mgpublicidad.mx/')}>
            <Col md={7} align='center'>
              <div className='section-hover-mg'><img src={mg} className='section-hover-mg section-fakeimage'/></div>
            </Col>
            <Col md={5}>
            <div className='section-div'>MG PUBLICIDAD</div>
              <div className='section-desc section-desc-p'>Somos una agencia de marketing, creatividad y publicidad, enfocada en construir experiencias innovadoras para lograr los objetivos de nuestros clientes.<br/>
                Nuestro equipo està conformado por profesionales especializados en cada àrea para brindarte asesorìa y soluciones personalizadas. Trabajaremos mano a mano contigo para que tu marca se posicione y alcance el éxito que buscas.</div>
            </Col>
          </Row>*/}
          
          <div className='manita-cel section-web'>
            <Row className="align-items-center manita pb-4"  onClick={() => window.open('https://inmobiplace.com/')}>
              <Col md={5}  align='right'>
                <div className='section-div'>INMOBIPLACE</div>
                <div className='section-desc section-desc-p'>Creamos soluciones rentables y efectivas para impulsar el crecimiento de agencias inmobiliarias. A travès de canales digitales y tradicionales, nuestro equipo de especialistas desarrolla el mensaje ideal para que obtengas resultados efectivos, medibles y concretos. Ademàs, nos dedicamos a la comercializaciòn dentro del mercado inmobiliario, comercial, residencial e industrial. </div>
              </Col>
              <Col md={7} align="center">
                <div className='section-hover-inmobi'><img src={inmobi} className='section-hover-inmobi section-fakeimage' /></div>
              </Col>
            </Row>
          </div>
          
          <div className='section-cel'>
            <Row className="align-items-center manita pb-4"  onClick={() => window.open('https://inmobiplace.com/')}>
              <Col md={7} align='center'>
              <div className='section-hover-inmobi'><img src={inmobi}  className='section-hover-inmobi section-fakeimage'/></div>
          
              </Col>
              <Col md={5} align='center' >
              <div className='section-div'>INMOBIPLACE</div>
                <div className='section-desc section-desc-p'>Creamos soluciones rentables y efectivas para impulsar el crecimiento de agencias inmobiliarias. A travès de canales digitales y tradicionales, nuestro equipo de especialistas desarrolla el mensaje ideal para que obtengas resultados efectivos, medibles y concretos. Ademàs, nos dedicamos a la comercializaciòn dentro del mercado inmobiliario, comercial, residencial e industrial. </div>
              </Col>
            </Row>
          </div>
          
          {/*<Row className="align-items-center manita pb-4"  onClick={() => window.open('https://mandarinapromocionales.mx/')}>
            <Col md={7} align='center'>
              <div className='section-hover-mandarina' ><img src={mandarina} className='section-hover-mandarina
               section-fakeimage'/></div>
              
            </Col>
            <Col md={5}>
              <div className='section-div' >MANDARINA PROMOCIONALES</div>
              <div className='section-desc section-desc-p'>Somos una agencia que ofrece una cuidada selecciòn de productos promocionales, calidad premium y eco-responsables, a fin de acercar a nuestros clientes un mundo de experiencias y sensaciones a travès de obsequios ùtiles, innovadores, funcionales y ecològicos.</div>
            </Col>
          </Row>*/}

          <div className='manita-cel section-web'>
            <Row className="align-items-center manita pb-4" onClick={() => window.open('https://afirmativo.mx/')}>
              <Col md={7} align="center">
                <div className='section-hover-afirmativo'><img src={afirmativo} className='section-hover-afirmativo section-fakeimage' /></div>
              </Col>
              <Col md={5}>
                <div className='section-div'>AFIRMATIVO</div>
                <div className='section-desc section-desc-p'>Somos expertos en convertir tus ideas en nuevos productos. A travès del diseño industrial, gràfico y arquitectònico, diseñamos sin lìmites y te ofrecemos soluciones hechas a tu medida. Nuestra tecnologìa, experiencia y creatividad, son las herramientas para llevar a tu marca a donde necesita estar.</div>
              </Col>
            </Row>
          </div>
          
          <div className='section-cel'>
            <Row className="align-items-center manita pb-4" onClick={() => window.open('https://afirmativo.mx/')}>
              <Col md={7} align='center'>
              <div className='section-hover-afirmativo'><img src={afirmativo}  className='section-hover-afirmativo section-fakeimage'/></div>
          
              </Col>
              <Col md={5} align='center' >
              <div className='section-div'>AFIRMATIVO</div>
                <div className='section-desc section-desc-p'>Somos expertos en convertir tus ideas en nuevos productos. A travès del diseño industrial, gràfico y arquitectònico, diseñamos sin lìmites y te ofrecemos soluciones hechas a tu medida. Nuestra tecnologìa, experiencia y creatividad, son las herramientas para llevar a tu marca a donde necesita estar.  </div>
              </Col>
            </Row>
          </div>
        </Container>  
      </div>
      
        <div className='clearfixe'></div>
      <div className='section-footer'>
        <Row className="align-items-center">
          <Col md={3}>
            <img src={logo}  width="60" height="60" className="d-inline-block align-top pading" />
          </Col>
          <Col md={6}>
            <div onClick={() => setLgShow(true)} style={{ cursor: 'pointer', fontSize: "15px", padding: "15px 0 0 0" }}>AVISO DE PRIVACIDAD</div>
            <a href="mailto:contacto@grupomebs.com"><div style={{ cursor: 'pointer', fontSize: "15px", padding: "5px 0 15px 0", color: "#fff" }}>contacto@grupomebs.com</div></a>
          </Col>
          <Col md={3}> <a href="https://www.linkedin.com/company/grupo-mebs/"><Linkedin className='icon-nav' style={{ fontSize: '20px' }}/></a>  </Col>
        </Row>
        </div>
     
    </>
  );
}

export default App;
